import React, { useEffect, useState }   from 'react'
import axios                            from 'axios'
import Spinner                          from './Spinner'
import { obfuscate }                    from '../utils/utils'
import styles                           from './BannerAd.module.css'


const BannerAd = ({name}) => {

    const defaultBanner = {
        name:       'dailyForecastMessage',
        srcset:     '/img/promo/dailyForecastMsgMobile.jpg',
        media:      '(max-width: 768px)',
        type:       'image/jpeg',
        src:        '/img/promo/dailyForecastMsg.jpg',
        alt:        'Daily Forecast Message',
        ariaLabel:  'Daily Forecast Message',
        href:       '/year-ahead-reading',
        rel:        '' /* noopener noreferrer external nofollow */
    }

    const [banner, setBanner] = useState(defaultBanner);

    useEffect(() => {
        const fetchBanner = async () => {
            try
            {
                const response = await axios.get(`/api/bannerad?c=${obfuscate(name)}`);
                setBanner(response.data);
            }
            catch (error)
            {
                // console.error(`fetchIssue:\n${error}\n`);
                setBanner(defaultBanner);
            }
        };

        if ((! name) || (name === 'dailyForecastMessage'))
            setBanner(defaultBanner);
        else
            fetchBanner();

        // eslint-disable-next-line
    }, []);


  return (

    <>
    <div className="catflap">
    {
        !banner ? (
            <>
                <Spinner sizeClass="small" />
            </>
        ) : (
            <>
                <div className={styles.bannerAd}>
                  <picture>
                    {banner.srcset && banner.media && (
                      <source srcSet={banner.srcset} media={banner.media} type="image/jpeg" />
                    )}
                    <a href={banner.href} rel={banner.rel}>
                        <img
                          src={banner.src}
                          alt={banner.alt}
                          loading="lazy"
                          aria-label={banner.ariaLabel}
                          className={styles.bannerImage}
                        />
                    </a>
                  </picture>
                </div>
            </>
        )
    }
    </div>
    </>
  );
};

export default BannerAd;
