import React, { useState, useRef, useEffect }   from 'react'
import { Canvas }                               from '@react-three/fiber'
import * as THREE                               from 'three'

import Spinner                                  from '../components/Spinner'

import styles                                   from './CardSpread.module.css'


const Card = ({ position, cardWidth, cardImage, cardId, onSelect, isSelected }) => {

  const handleCardClick = () => {
    onSelect(cardId);
  };

  const texture = new THREE.TextureLoader().load(cardImage);

  return (
    <mesh
      position={position}
      onClick={handleCardClick}
      scale={isSelected ? 1.2 : 1}
    >
      <planeGeometry args={[cardWidth, cardWidth * (16 / 9)]} />
      <meshStandardMaterial map={texture} color={isSelected ? "#ff6b6b" : "white"} />
    </mesh>
  );
};


const CardSpread = ({ totalCards = 22, targetCards = 3, cardBackImage, onCardsSelected }) => {

    const [selectedCards, setSelectedCards]           = useState([]);
    const [selectionComplete, setSelectionComplete]   = useState(false);

    const [loading, setLoading]                       = useState(false);
    const [scrollOffset, setScrollOffset]             = useState(0);
    const velocityRef                                 = useRef(0);
    const isDraggingRef                               = useRef(false);
    const lastMouseXRef                               = useRef(0);
    const containerRef                                = useRef(null);

    const maxScrollOffset = -(totalCards - 1) * 3.5;
    const bounceFactor    = 0.3;
    const cardWidth       = 3;
    const spacing         = 3.5;

    const positions =
        Array.from({ length: totalCards }).map(
            (_, index) => [index * spacing + scrollOffset, 0, 0, ]
        );

    const handleConfirm = async () => {
        try
        {
            setLoading(true);
            onCardsSelected(selectedCards);
        }
        catch (error)
        {
            console.error(`Error confirming cards: {error}`);
        }
    };

    const handleCardSelect = (cardId) => {
        if (selectedCards.includes(cardId))
        {
            setSelectedCards(selectedCards.filter((id) => id !== cardId));
            setSelectionComplete(false);
        }
        else if (selectedCards.length < targetCards)
        {
            setSelectedCards([...selectedCards, cardId]);

            if (selectedCards.length + 1 === targetCards)
                setTimeout(() => setSelectionComplete(true), 300);
        }
    };

    const handleKeyDown = (e) => {
        const scrollAmount = 1;

        if (["ArrowLeft", "a"].includes(e.key))
            velocityRef.current = scrollAmount;

        if (["ArrowRight", "d"].includes(e.key))
            velocityRef.current = -scrollAmount;
    };

    const handleMouseDrag = () => {

        const container = containerRef.current;

        const mouseDownHandler = (e) => {
            isDraggingRef.current   = true;
            velocityRef.current     = 0;
            lastMouseXRef.current   = e.pageX;
        };

        const mouseMoveHandler = (e) => {

            if (!isDraggingRef.current)
                return;

            const deltaX              = e.pageX - lastMouseXRef.current;
            velocityRef.current       = deltaX * 0.05;
            setScrollOffset((prev) => prev + deltaX * 0.01);
            lastMouseXRef.current     = e.pageX;
        };

        const mouseUpHandler = () => {
            isDraggingRef.current = false;
        };

        container.addEventListener("mousedown",     mouseDownHandler);
        container.addEventListener("mousemove",     mouseMoveHandler);
        container.addEventListener("mouseup",       mouseUpHandler);
        container.addEventListener("mouseleave",    mouseUpHandler);

        return () => {
            container.removeEventListener("mousedown",  mouseDownHandler);
            container.removeEventListener("mousemove",  mouseMoveHandler);
            container.removeEventListener("mouseup",    mouseUpHandler);
            container.removeEventListener("mouseleave", mouseUpHandler);
        };
    };


    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, []);

    useEffect(() => {
        handleMouseDrag();
    }, []);

    useEffect(() => {
        const animateScroll = () => {

        if (!isDraggingRef.current)
        {
            velocityRef.current *= 0.9; // Apply friction to slow down

            setScrollOffset((prev) => {

                const newOffset = prev + velocityRef.current;

                // Bounce effect at the ends
                if (newOffset > 0) {
                    velocityRef.current *= -bounceFactor;
                    return 0;
                }
                if (newOffset < maxScrollOffset) {
                    velocityRef.current *= -bounceFactor;
                    return maxScrollOffset;
                }

                return newOffset;
            });

            if (Math.abs(velocityRef.current) < 0.01)
                velocityRef.current = 0;
            }

            requestAnimationFrame(animateScroll);
        };

        animateScroll();
    }, [maxScrollOffset]);



  return (
    <div className={styles.cardSpread} ref={containerRef}>

      <div className={styles.cardCounter}>
        {selectedCards.length} of {targetCards} cards selected
      </div>

      <Canvas camera={{ position: [0, 0, 15], fov: 50 }}>
        <ambientLight intensity={1.5} />
        {positions.map((position, index) => (
          <Card
            key={index}
            cardId={index}
            position={position}
            cardWidth={cardWidth}
            cardImage={cardBackImage}
            onSelect={handleCardSelect}
            isSelected={selectedCards.includes(index)}
          />
        ))}
      </Canvas>

      { selectionComplete && (
        <div className={`${styles.modal} ${styles.modalFadeIn}`}>
          <div className={styles.modalContent}>
            <h2>Confirm Your Selection</h2>
            <p>You selected cards: {selectedCards.join(", ")}</p>
            <button onClick={handleConfirm}>Confirm</button>
            <button onClick={() => { setSelectedCards([]); setSelectionComplete(false); }}>
              Reset
            </button>
          </div>
        </div>
      )}

      { loading && (
        <>
        <Spinner sizeClass="medium" />
        <h4>loading..</h4>
        </>
      )}

    </div>
  );
};

export default CardSpread
