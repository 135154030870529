
const journeys = {

    "sign-in": {
        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Dashboard",
                url:        "/dashboard",
                linkType:   "nav",
                complete:   0
            }
        ]
    },

    "register": {
        steps: [
            {
                stepName:   "Reg Options",
                url:        "/register",
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Dashboard",
                url:        "/dashboard#top",
                linkType:   "nav",
                complete:   0
            }
        ]
    },

    "mailing-list-subscribe": {
        steps: [
            {
                stepName:   "Reg Options",
                url:        "/register",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Notify Subscribe Confirmation",
                url:        "/confirm-sub",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Home",
                url:        "/",
                linkType:   "nav",
                complete:   0
            }
        ]
    },

    "year-ahead-purchase": {

        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Collect Start Date",
                url:        "/get-start-date",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Pay",
                url:        "/year-ahead-reading",
                linkType:   "nav",
                complete:   0
            }
        ],
    },

    "love-compatibility-purchase" : {
        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Collect Birthday Details",
                url:        "/get-personal",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Collect Partner Birth Details",
                url:        "/get-partner",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Pay",
                url:        "/love-horoscope-reading",
                linkType:   "nav",
                complete:   0
            }
        ],
    },

    "tarot-3-questions-reading": {
        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Ask Questions",
                url:        "/tarot-ask-3-questions",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Card Selection",
                url:        "/tarot-3-card-selection",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Pay",
                url:        "/dashboard",
                linkType:   "nav",
                complete:   0
            }
        ],
    },

    "tarot-celtic-cross-reading": {
        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Ask Question",
                url:        "/ask-question",
                linkType:   "nav",
                complete:   0
            },

            {
                stepName:   "Pay",
                url:        "/dashboard",
                linkType:   "nav",
                complete:   0
            }
        ],
    },

    "test-purchase": {
        steps: [
            {
                stepName:   "Sign In",
                url:        `/signin`,
                linkType:   "href",
                complete:   0
            },

            {
                stepName:   "Pay",
                url:        "/dashboard",
                linkType:   "nav",
                complete:   0
            }
        ],
    }
};

export {
    journeys
}
