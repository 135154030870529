import React            from 'react'
import { HashLink }     from 'react-router-hash-link'
import Styles           from './YearAheadSection.module.css'


const YearAheadSection = () => {

    return (
    <>
    <section id="YearAheadReadingSection" className={Styles.yearAheadSection}>
        <div className={Styles.textContainer}>
            <h2>My Personalised Year-Ahead Horoscope Reading for You</h2>
            <h3>Let me reveal what lies ahead for you</h3>
            <p>
                Allow me to craft a unique and personal forecast for your year ahead.
                Using the exact place, time, and date of your birth, I'll unveil what
                the stars have in store just for you.
            </p>
            <p>
                Together, we'll explore key insights about your career, love life, health, and more.
                With my celestial guidance, you'll step into the future with clarity and confidence.
            </p>

            <div className={Styles.callToAction}>
                <HashLink smooth to="/year-ahead-reading" className={`${Styles.ctaButton}`}>
                    Let’s Begin Your Journey
                </HashLink>
            </div>
        </div>

        <div className={Styles.imageContainer}>
            <img
                src="/img/year-ahead-crystal-ball.jpg"
                alt="a universe in a crystal ball"
                className={Styles.image}
                loading="lazy"
            />
        </div>
    </section>
    </>
    )
}

export default YearAheadSection
