import React, { useState }  from 'react'
import { useNavigate }      from 'react-router-dom'
import { Helmet }           from 'react-helmet'
import DatePicker           from 'react-date-picker'
import Spinner              from '../components/Spinner'

import { useAuth }          from '../auth/AuthContext'
import { continueJourney }  from '../session/journeyLogic'
import { setJourneyData }   from '../session/journeyState'

import { console_error } from '../utils/utils'


import 'react-date-picker/dist/DatePicker.css'
import styles from './GetStartDatePage.module.css'


const GetStartDatePage = () => {

    const title             = 'Starting Date  |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    const navigate                  = useNavigate();
    const { isAuthenticated }       = useAuth();

    const today         = new Date();
    const defaultDate   = new Date(2025, 0, 1);
    const initialDate   = defaultDate < today ? today : defaultDate;

    const [selectedDate, setSelectedDate]   = useState(initialDate);
    const [loading, setLoading]             = useState(false);


    const handleDateChange = (newDate) => {

        if (newDate) {
            const utcDate = new Date(Date.UTC(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate()
            ));

            setSelectedDate(utcDate);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try
        {
            setJourneyData('yearAheadStartDate', selectedDate);
            await continueJourney(navigate, isAuthenticated);
        }
        catch(err)
        {
            console_error(`GetStartDatePage::handleSubmit error: ${JSON.stringify(err)}\n\n`);
        }
    };

    return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={websiteUrl} />
        <meta property="og:url" content={websiteUrl} />
        <meta name="robots" content="noindex" />
    </Helmet>

    <div id="top"></div>

    <div className={styles.page}>

        <h1>Year-Ahead Reading</h1>
        <h2>Select a start date for your reading</h2>

        <form onSubmit={handleSubmit}>
            <div className={styles.datePickerContainer}>
                <DatePicker
                    onChange={handleDateChange}
                    value={selectedDate}
                    disabled={loading}
                    required
                />
            </div>

            <button
                type="submit"
                disabled={loading}
                className={`${styles.submitButton} ${loading ? styles.disabledButton : ''}`} // Add dynamic class
            >
                { loading ? <Spinner sizeClass="tiny" /> : null }
                Continue
            </button>

        </form>

    </div>
    </>
    );
};

export default GetStartDatePage;
