import React, { Suspense } from 'react'

import Header               from './components/Header'
import Footer               from './components/Footer'
import AppRoutes            from './routes'

import './App.css'

const CookieConsentModal =
    React.lazy(() => import('./components/CookieConsentModal'));



const App = () => {

    return (
    <>
    <Header />

        <Suspense fallback={<div>Loading...</div>}>
            <CookieConsentModal />
        </Suspense>

        <AppRoutes />

    <Footer />
    </>
    )
}

export default App
