import React, { useState/*, useEffect*/ }   from 'react'
import { useNavigate }                      from 'react-router-dom'
import { HashLink }                         from 'react-router-hash-link'
import { startJourney }                     from '../session/journeyState'
import { continueJourney }                  from '../session/journeyLogic'
import { useAuth }                          from '../auth/AuthContext'
import useSubscriptionStore                 from '../stores/subscriptionStore'
import Spinner                              from './Spinner'
import Styles                               from './HeroSection.module.css'


const HeroSection = () => {

    const { isAuthenticated }       = useAuth();
    const { isSubscribed }          = useSubscriptionStore();
    const [isJoining, setIsJoining] = useState(false);
    const navigate                  = useNavigate();

    const handleJoinNow = async () => {
        setIsJoining(true);
        startJourney('mailing-list-subscribe');
        await continueJourney(navigate, isAuthenticated);
    }


    return (
    <>
    <section id="HeroSection" className={Styles.heroSection}>

        <div className={Styles.imageContainer}>
            <picture>
                <source media="(min-width: 769px)" srcSet="/img/astrology-hero-background.webp" />
                <source media="(max-width: 768px)" srcSet="/img/astrology-hero-background-mobile.webp" />

                <img
                    src="/img/astrology-hero-background.webp"
                    alt="StarGoddess Astrology - your personal resource for astrology, horoscopes, tarot readings and more"
                    className={Styles.starGoddessImage}
                    importance="high"
                />
            </picture>
        </div>

        <div className={Styles.titleContainer}>
            <h1 className={Styles.fadeInUpAnim}>StarGoddess Astrology</h1>
            <ul>
                <li>✨<HashLink smooth to={'/#ForecastSection'}>Free Daily Horoscopes</HashLink></li>
                <li>&nbsp;</li>
                <li>Personalised Readings</li>
                <li>😍 <HashLink smooth to={'/year-ahead-reading'}>Year-Ahead</HashLink></li>
                <li>😍 <HashLink smooth to={'/love-horoscope-reading'}>Love Horoscopes</HashLink></li>
                <li>🥰 <HashLink smooth to={'/#TarotSection'}>Tarot Readings</HashLink></li>
            </ul>
        </div>

        <div className={Styles.textContainer}>
            <h2>
                { ((!isSubscribed) || ! isAuthenticated) ?
                    '📩 My horoscope delivered daily' :
                    `❤️ You're a daily horoscope subscriber` }
            </h2>

            <button
                disabled={isJoining || (isSubscribed && isAuthenticated) }
                onClick={handleJoinNow}
                className={Styles.ctaButton}
            >
                {
                    isJoining ?
                    <Spinner sizeClass="tiny" /> : ((! isSubscribed|| !isAuthenticated) ? 'Count Me In' : 'Thank You')
                }
            </button>
        </div>
    </section>
    </>
    )
}

export default HeroSection
