import React from 'react'
import { HashLink } from 'react-router-hash-link'
import Styles from './TarotSection.module.css'


// <HashLink smooth to='/tarot-3-card-reading' className={Styles.button}>Ask Questions</HashLink>
// <HashLink smooth to='/tarot-celtic-cross-reading' className={Styles.button}>Deep-Dive Reading</HashLink>

const TarotSection = () => {

  return (
    <section id="TarotSection" className={Styles.tarotSection}>

      <div className={Styles.titleRow}>
        <h2 className={Styles.title}>Tarot Readings</h2>
      </div>

      <div className={Styles.contentRow}>

        <div className={Styles.column}>
          <h3>Ask the Tarot 3 Questions</h3>

          <div className={Styles.imageContainer}>
            <img src="/img/3-card-spread.jpg" alt="3 Tarot Questions" className={Styles.image} />
          </div>

          <p>Do you have a burning desire to know what lies ahead?</p>
          <p>Then ask three questions about your future!</p>
          <p>Whether it’s love, career, or personal growth, you'll get deep, meaningful answers from the Tarot.</p>

          <div className={Styles.callToAction}>
            <HashLink smooth to='/#TarotSection' className={Styles.button}>Coming Soon</HashLink>
          </div>
        </div>


        <div className={Styles.column}>
          <h3>In-Depth Tarot Reading</h3>

          <div className={Styles.imageContainer}>
            <img src="/img/celtic-cross-spread.jpg" alt="Celtic Cross Tarot Reading" className={Styles.image} />
          </div>

          <p>Perhaps you need in-depth insights into your future..</p>
          <p>Are you starting a new business venture?  Maybe your love life is in question..</p>
          <p>Our Celtic Cross tarot reading will provide profound insights into your life's journey.</p>

          <div className={Styles.callToAction}>
            <HashLink smooth to='/#TarotSection' className={Styles.button}>Coming Soon</HashLink>
          </div>

        </div>
      </div>
    </section>
  );
};

export default TarotSection;
