import React, { useState, useEffect, useRef }   from 'react'
import { useNavigate }                          from 'react-router-dom'
import CardSpread                               from '../components/CardSpread'
import Spinner                                  from '../components/Spinner'
import { setJourneyData }                       from '../session/journeyState'
import { continueJourney }                      from '../session/journeyLogic'

import styles from './CardSelectionPage.module.css'


const CardSelectionPage = ({numCards, numSelect}) => {

    const navigate              = useNavigate();
    const topRef                = useRef(null);
    const [loading, setLoading] = useState(false);


    const handleCardsSelected = async (selectedCards) => {
        try
        {
            setLoading(true);
            setJourneyData('tarot3CSSelectedCards', [...selectedCards]);

            await continueJourney(navigate);
        }
        catch (error)
        {
            console.error(`Error confirming cards: {error}`);
        }
    }

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
        <>
        <div ref={topRef}></div>

        <div className={styles.page}>

            { ! loading ? (
                <>
                <div className={styles.header}>
                    <h1>Choose Your Cards</h1>
                    <p>Select the cards that speak to you..</p>
                </div>

                <div className={styles.main}>
                    <CardSpread
                      totalCards={numCards}
                      targetCards={numSelect}
                      cardBackImage={"/img/card-back-1.png"}
                      onCardsSelected={handleCardsSelected} />
                </div>
                </>
            )
                :
            (
                <>
                <div className={styles.header}>
                    <h1>Card Selection Complete</h1>
                    <p>Thank you..</p>
                </div>

                <div className={styles.main}>
                    <h3>one moment please..</h3>
                    <Spinner sizeClass="medium" />
                </div>
                </>
            )}

        </div>
        </>
    );
};

export default CardSelectionPage
