import React, { useState } from 'react'

import Styles from './AccordionFAQ.module.css'

const AccordionFAQ = ({ title, faqs }) => {

    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const itemType = 'https://schema.org/FAQPage'

    return (
        <div className={Styles.accordion} itemScope itemType={itemType}>

            <h2>{title}</h2>

            {faqs.map((faq, index) => (
                <div
                    key={index}
                    className={Styles.accordionItem}
                    itemProp="mainEntity"
                    itemScope
                    itemType="https://schema.org/Question"
                >
                    <div className={Styles.accordionHeader} onClick={() => toggleItem(index)}>
                        <h3 itemProp="name">{faq.question}</h3>
                    </div>
                    <div
                        className={`${Styles.accordionBody} ${openIndex === index ? Styles.open : ''}`}
                        itemProp="acceptedAnswer"
                        itemScope
                        itemType="https://schema.org/Answer"
                    >
                        <div itemProp="text">
                            {faq.answer}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AccordionFAQ
