import React, { useEffect, useRef }     from 'react'
import { Link }                         from 'react-router-dom'
import { Helmet }                       from 'react-helmet'

import Styles from './RefundPolicyPage.module.css'


const RefundPolicyPage = () => {

    const topRef = useRef(null);

    const title             = 'Refund Policy |  StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`
    //const websiteName       = 'StarGoddess.co'
    const contactEmail      = 'mailto:enquiries@StarGoddess.co'

    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
    <>
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={websiteUrl} />
        <meta property="og:url" content={websiteUrl} />
        <meta name="robots" content="noindex" />
    </Helmet>

    <div ref={topRef}></div>

    <div className={`${Styles.page} ${Styles.container} ${Styles.SmallPrint} ${Styles.justifiedContent}`}>

        <div>
            <div xs={8}>
                <h1 className="display-4 fw-normal">Refund Policy</h1>
                <p>Last updated 11th September 2024</p>

                <h2>No Refunds on Personalized Content</h2>
                <p>At StarGoddess, we take pride in offering uniquely personalized content tailored specifically to each customer’s preferences and requirements. Due to the bespoke nature of our services and products, we are unable to offer refunds once an order has been processed and the personalized content creation has begun.</p>

                <h2>Customer Satisfaction</h2>
                <p>We strive to meet and exceed your expectations with every personalized product we deliver.  If you have any concerns or issues with the service you receive, please reach out to us at the email below and we will endeavour to rectify any issues you may have.  We are committed to ensuring your satisfaction within the scope of our no-refund policy.</p>

                <h2>Personalized Service Agreement</h2>
                <p>By placing an order on our website, you acknowledge and agree to the following terms:</p>
                <ul style={{"listStyleType": "none"}}>
                    <li><strong>Personalization of Content:</strong> You understand that the content you are purchasing is customized for you based on the specifications and details you provide at the time of ordering. This personalized content is uniquely yours and does not have generic applicability.</li>
                    <li><strong>No Return:</strong> Due to the customized nature of the services, the content cannot be returned. As such, all purchases are final once your order is confirmed and the creation process has commenced.</li>
                    <li><strong>Consent to No Refund Policy:</strong> You expressly agree that no refunds will be issued for any personalized content delivered to you under any circumstances. This policy is in place because once the personalization process begins, resources and time are dedicated immediately to creating your unique content, which cannot be reused or resold to anyone else.</li>
                </ul>

                <h2>Cancellation Policy</h2>
                <p>If you wish to cancel your order, please contact us immediately.  If your personalized content creation has not yet commenced, we may consider a cancellation request. However, once the process has started, we strictly cannot accept cancellations, and no refunds will be possible.</p>

                <h2>For More Information</h2>
                <p>In order to resolve any issues or to receive further information regarding our no refund policy, please contact us at the link below:</p>

                <ul style={{"listStyleType": "none"}}>
                    <li><Link to={contactEmail} style={{color: 'gray'}}>By email</Link></li>
                </ul>
            </div>
        </div>
    </div>

    </>
    )
}

export default RefundPolicyPage
