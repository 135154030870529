import React, {useEffect, useRef, useState} from 'react'
import { useParams }                        from 'react-router-dom'
import { Helmet }                           from 'react-helmet'

import he                                   from 'he'
import axios                                from 'axios'
import StarSignCard                         from '../components/StarSignCard'
import SubscribeBox3                        from '../components/SubscribeBox3'
import Spinner                              from '../components/Spinner'
import BannerAd                             from '../components/BannerAd'
import LoveHoroscopeBanner                  from '../components/LoveHoroscopeBanner'
import { capitalize, formatDateString }     from '../utils/utils'

import Styles from './ForecastPage.module.css';


const ForecastPage = () => {

    const topRef                                = useRef(null);
    let { starSign }                            = useParams();
    starSign                                    = capitalize(starSign);
    const [dailyForecast, setDailyForecast]     = useState('');
    const [dailyLoading, setDailyLoading]       = useState(true);

    const sDate             = formatDateString(new Date().toISOString().split('T')[0]);
    const title             = `Daily Horoscope Forecast for ${starSign}`
    const websiteUrl        = `${window.location.href}`
    const metaDescription   = `Daily horoscope for ${starSign} on ${sDate} | Personalized Astrology Readings & Horoscopes | StarGoddess Astrology`


    const getDayForecastTitle = () => {
        const date          = new Date();
        const options       = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Correctly handle the formatted date
        const [weekday, month, day, year] = formattedDate.replace(',', '').split(' ');

        const ordinal = (n) => {
            const s = ["th", "st", "nd", "rd"];
            const v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        };

        return `${weekday} ${ordinal(parseInt(day, 10))} ${month} ${year}`;
    };


    useEffect(() => {
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    useEffect(() => {

        const fetchDailyForecast = async () => {
            try
            {
                setDailyLoading(true);

                const response = await axios.get(
                    `${process.env.REACT_APP_API_ENDPOINT}/api/forecast`, {
                        params: {
                            starsign: starSign,
                            duration: 'day',
                            dateFrom: new Date().toISOString().split('T')[0]
                        }
                    });

                setDailyForecast(he.decode(response?.data?.forecast));
            }
            catch (error)
            {
                console.error('Error fetching daily forecast:', error);
                setDailyForecast('Error fetching daily forecast.');
            }
            finally
            {
                setDailyLoading(false);
            }
        };

        fetchDailyForecast();

    }, [starSign]);


    return (
    <>

    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        {
            window.location.href.includes('daily-horoscope') && (
                <link rel="canonical" href={websiteUrl} />
            )
        }
        <meta property="og:url" content={websiteUrl} />
    </Helmet>

    <div ref={topRef}></div>

    <div className={Styles.page}>

        <div className={Styles.forecastContainer}>

            <div className={Styles.pageHeaderBar}>
                <div className={Styles.column} style={{ width: '100%' }}>
                    <h1>{ starSign } Forecast </h1>
                    <h2> { getDayForecastTitle() }</h2>
                    <StarSignCard starSign={starSign} withLink={true} />
                </div>
            </div>

            {
                dailyLoading ?
                (<Spinner sizeClass="small" />) :
                (
                    <>
                    <div dangerouslySetInnerHTML={{__html: dailyForecast}} />
                    <SubscribeBox3 />
                    <BannerAd name='dailyForecastMessage' />
                    </>
                )
            }

        </div>

    </div>
    </>
    )
}

export default ForecastPage
