
import { journeys }
    from '../json/journeys'

import { b64Encode, b64Decode, obfuscate, deobfuscate, console_error }
    from '../utils/utils'


// -- journey state ---------------------------------------
const getJourneyState = () => {

    try
    {
        const storedState = b64Decode(localStorage.getItem('journey'));

        return storedState ? JSON.parse(storedState) : {
            journeyName:        null,
            journey:            null,
            currentStepIndex:   0
        };
    }
    catch (err)
    {
        console_error(`getJourneyState err: ${err}\n`);
    }
};

const getJourneyObjects = () => {
    try
    {
        const { journeyName, journey, currentStepIndex } = getJourneyState();

        if (! journey)
            return null;

        const { stepName } = journey[currentStepIndex];

        // console.log(`⚠ Journey: ${journeyName}, Step: ${stepName}\n`);

        return { journey, journeyName, stepName, currentStepIndex };
    }
    catch(err)
    {
        console_error(`getJourneyObjects: ${JSON.stringify(err)}`);
    }
}

const setJourneyState = (state) => {
    localStorage.setItem('journey', b64Encode(JSON.stringify(state)));
};

const removeJourneyState = () => {
    localStorage.removeItem('journey');
};

// -- journey data ----------------------------------------
const setJourneyData = (label, data) => {

    const storedData =
        localStorage.getItem('journeyData') ?
            JSON.parse(deobfuscate(localStorage.getItem('journeyData'))) : {};

    storedData[label] = data;

    localStorage.setItem('journeyData', obfuscate(JSON.stringify(storedData)));
};

const journeyDataToConsole = () => {
    const storedData =
        localStorage.getItem('journeyData') ?
            JSON.parse(deobfuscate(localStorage.getItem('journeyData'))) : {};

    console.log(`journeyData:\n${JSON.stringify(storedData)}\n\n`);
}

const getJourneyData = (label) => {

    const storedData =
        localStorage.getItem('journeyData') ?
            JSON.parse(deobfuscate(localStorage.getItem('journeyData'))) : {};

    return storedData[label] || null;
};

const removeJourneyData = (label) => {

    const storedData =
        localStorage.getItem('journeyData') ?
            JSON.parse(deobfuscate(localStorage.getItem('journeyData'))) : {};

    delete storedData[label];

    localStorage.setItem('journeyData', obfuscate(JSON.stringify(storedData)));
};

const clearJourneyData = () => {
    localStorage.removeItem('journeyData');
}

// -- journey----------------------------------------------

const startJourney = (journeyName) => {

    const journey = journeys[journeyName];

    if (! journey)
    {
        console.error(`Invalid journey name: ${journeyName}. Please provide a valid journey name.`);
        return { journeyName: null, journey: null, currentStepIndex: 0 };
    }


    clearJourneyData();

    const currentJourney = journey;
    const newState       = { journeyName, journey: currentJourney.steps, currentStepIndex: 0 };
    setJourneyState(newState);

    console.log(`startJourney: ${journeyName}`);
    
    return newState;
};

const endJourney = () => {

    const state = getJourneyObjects();

    if (! state)
        return;

    const { journeyName } = state;

    console.log(`ℹ Journey Complete: ${journeyName}\n`);
    console.trace();

    removeJourneyState();
    clearJourneyData();
};

const gotoStep = (stepName) => {

    // only used in emergencies eg. if the user has somehow missed steps in the journey, or
    // failed to complete data requirements before payment.
    // use sparingly


    const state = getJourneyState();

    if (! state) {
        console.error("gotoJourneyStep failed; No active journey state found.");
        return;
    }

    const { journey, currentStepIndex } = state;

    const targetStepIndex = journey.findIndex(step => step.stepName === stepName);

    if (targetStepIndex === -1) {
        console.error(`Step "${stepName}" not found in the current journey.`);
        return;
    }

    if (targetStepIndex > currentStepIndex) {
        console.error(`Cannot move forward to step "${stepName}" directly. Use completeStep instead.`);
        return;
    }

    // Reset completion for steps after the target step
    for (let i = targetStepIndex + 1; i < journey.length; i++) {
        journey[i].complete = 0;
    }

    const newState = { ...state, journey, currentStepIndex: targetStepIndex };
    setJourneyState(newState);

    return newState;
};

const completeStep = () => {

    // marks current step as complete
    // advances the current step; ends journey if we've completed the last step

    const state                                             = getJourneyState();
    const { /*journeyName,*/ journey, currentStepIndex }    = state;

    // const { stepName } = journey[currentStepIndex];

    // console.log(`ℹ completeStep: Journey: ${journeyName}, Step: ${stepName}\n`);

    journey[currentStepIndex].complete = 1;

    const newStepIndex = currentStepIndex + 1;

    if (newStepIndex < journey.length)
    {
        const newState = { ...state, journey, currentStepIndex: newStepIndex };
        setJourneyState(newState);
        return newState;
    }
    else
    {
        return endJourney();
    }
};

const processStep = (navigate) => {

    const state = getJourneyObjects();
    if (! state)
    {
        console.log('processStep: no state, cannot proceed');
        return;
    }

    const { /*journeyName,*/ journey, currentStepIndex /*, stepName*/ } = state;

    // console.log(`processStep: Journey: ${journeyName}, Step: ${stepName}\n`);

    if (! journey || ! journey[currentStepIndex])
    {
        console.error("Journey doesn't exist or step is out of bounds");
        return;
    }

    const currentStep = journey[currentStepIndex];

    if (currentStep.url && currentStep.linkType)
    {
        completeStep();

        // console.log(`Step Link: ${currentStep.url}, link type: ${currentStep.linkType}`);

        if (currentStep.linkType === 'href')
            window.location.href = currentStep.url;

        else if (currentStep.linkType === 'nav')
            navigate(currentStep.url);
    }
    else
    {
        // console.log('this step has no link, marking as complete\n');
        completeStep();
    }
};
// --------------------------------------------------------

export {
    getJourneyState,
    getJourneyObjects,
    setJourneyState,
    removeJourneyState,
    setJourneyData,
    getJourneyData,
    journeyDataToConsole,
    removeJourneyData,
    clearJourneyData,
    startJourney,
    endJourney,
    gotoStep,
    completeStep,
    processStep
}
