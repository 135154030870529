import React, { useState }  from 'react'
import { useNavigate }      from 'react-router-dom'
import { Helmet }           from 'react-helmet'
import { setJourneyData }   from '../session/journeyState'
import { continueJourney }  from '../session/journeyLogic'

import Spinner              from '../components/Spinner'
import { console_error }    from '../utils/utils'


import styles from './Tarot3QuestionsPage.module.css'


const Tarot3QuestionsPage = () => {

    const navigate              = useNavigate();

    const [loading, setLoading] = useState(false);

    const [currentStep, setCurrentStep] = useState(1);

    const [questions, setQuestions] = useState({
        question1: '',
        question2: '',
        question3: ''
    });

    const title = 'Your 3 Tarot Questions'


    const handleNext = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setQuestions(prevQuestions => ({
            ...prevQuestions,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        try
        {
            e.preventDefault();
            setLoading(true);

            setJourneyData('tarot3CSQuestions', questions);
            await continueJourney(navigate);
        }
        catch(err)
        {
            console_error(`exception in handleSubmit:\n${err}\n\n`);
        }
        finally
        {
            setLoading(false);
        }
    };


    return (
        <>
        <Helmet>
            <title>{title}</title>
            <meta name="robots" content="noindex" />
        </Helmet>

        <div className={styles.page}>

            <h1>Your Tarot Questions</h1>
            <h2>Please provide your 3 most important questions, one at a time.</h2>

            <form onSubmit={handleSubmit} className={styles.formContainer}>

                {currentStep === 1 && (
                    <div className={styles.stepContainer}>
                        <label htmlFor="question1">Question 1</label>
                        <textarea
                            id="question1"
                            name="question1"
                            value={questions.question1}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                        <div className={styles.buttonContainer}>
                            <button type="button" onClick={handleNext} className={styles.nextButton}>Next</button>
                        </div>
                    </div>
                )}

                {currentStep === 2 && (
                    <div className={styles.stepContainer}>
                        <label htmlFor="question2">Question 2</label>
                        <textarea
                            id="question2"
                            name="question2"
                            value={questions.question2}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                        <div className={styles.buttonContainer}>
                            <button type="button" onClick={handleBack} className={styles.backButton}>Back</button>
                            <button type="button" onClick={handleNext} className={styles.nextButton}>Next</button>
                        </div>
                    </div>
                )}

                {currentStep === 3 && ! loading  && (
                    <div className={styles.stepContainer}>
                        <label htmlFor="question3">Question 3</label>
                        <textarea
                            id="question3"
                            name="question3"
                            value={questions.question3}
                            onChange={handleChange}
                            required
                            disabled={loading}
                        />
                        <div className={styles.buttonContainer}>
                            <button type="button" onClick={handleBack} className={styles.backButton}>Back</button>
                            <button type="submit" className={styles.submitButton} disabled={loading}>Submit</button>
                        </div>
                    </div>
                )}

                {currentStep === 3 && loading && (
                    <>
                    <Spinner sizeClass="medium" />
                    <h4>Loading..</h4>
                    </>
                )}
            </form>
        </div>
        </>
    );
};

export default Tarot3QuestionsPage
